<template>
	<div>
		<ZyroBackground
			:background-object.sync="background"
		/>
		<ZyroFieldToggle
			v-if="background.current === 'image'"
			id="backgroundAttachment"
			:label="$t('common.fixedBackground')"
			:checked="backgroundAttachment === 'fixed'"
			@input="backgroundAttachment = $event.target.checked ? 'fixed' : 'unset'"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { cloneDeep } from '@/utils/object';

export default {
	data() {
		return { blockId: '' };
	},
	computed: {
		...mapState(['currentBlockId']),
		...mapGetters(['currentBlock']),
		background: {
			get() {
				const { background } = this.currentBlock;
				const defaultBackground = {
					color: '#ffffff',
					current: 'color',
				};

				return ('current' in background)
					? background : defaultBackground;
			},
			set(newValue) {
				this.setBlockData({ data: { background: newValue } });
			},
		},
		backgroundAttachment: {
			get() {
				return this.currentBlock.settings.styles.attachment || 'unset';
			},
			set(newValue) {
				this.setBlockData({ data: { settings: { styles: { attachment: newValue } } } });
			},
		},
	},
	mounted() {
		this.blockId = this.currentBlockId;
		this.currentBlockBeforeEdit = cloneDeep(this.currentBlock);
	},
	beforeDestroy() {
		this.pushBlockDataToHistory({
			blockId: this.blockId,
			oldData: this.currentBlockBeforeEdit,
		});
	},
	methods: mapMutations([
		'setBlockData',
		'pushBlockDataToHistory',
	]),
};
</script>
