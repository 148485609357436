<template>
	<div class="visible-elements">
		<!-- TODO: https://github.com/zyro-inc/zyro/issues/1992 make this improvement and then update this -->
		<template v-for="(value, key) in valueMap">
			<div
				:key="`post-${key}-${value}`"
				class="visible-elements__list-item"
				:class="{ 'visible-elements__list-item--disabled': !currentBlock.settings.shownItems[key] }"
				@click="handleToggle(key)"
			>
				<ZyroLabel
					:bold="false"
					class="visible-elements__list-item-label"
				>
					{{ value }}
				</ZyroLabel>
				<ZyroSvg :name="currentBlock.settings.shownItems[key] ? 'eye-open' : 'eye-closed'" />
			</div>
			<ZyroSeparator :key="`separator-${key}`" />
		</template>
	</div>
</template>

<script>
import {
	mapGetters,
	mapMutations,
} from 'vuex';

export default {
	props: {
		valueMap: {
			type: Object,
			required: true,
		},
	},
	computed: mapGetters(['currentBlock']),
	methods: {
		...mapMutations(['setBlockData']),
		handleToggle(key) {
			const toggledValue = !this.currentBlock.settings.shownItems[key];

			this.setBlockData({ data: { settings: { shownItems: { [key]: toggledValue } } } });
		},
	},
};
</script>

<style lang="scss" scoped>
.visible-elements {
	&__list-item {
		display: flex;
		padding: 20px 0;
		cursor: pointer;
		transition: color 0.2s ease-in-out;

		&--disabled {
			color: $grey-600;
		}
	}

	&__list-item-label {
		padding-left: 16px;
		line-height: 16px;
	}
}
</style>
