<template>
	<div>
		<ZyroFieldRange
			v-model="postsPerPage"
			min="1"
			:max="maxPostsPerPage"
			:label="$t('builder.blog.blockBlogList.layout.postsPerPage')"
			units=""
		/>
		<ZyroSeparator />
		<ZyroFieldRange
			v-model="columnCount"
			min="1"
			max="4"
			:label="$t('common.columns')"
			units=""
		/>
		<ZyroSeparator />
		<ZyroFieldRange
			v-model="gridGapSize"
			min="24"
			max="80"
			step="4"
			:label="$t('common.columnGap')"
		/>
		<ZyroSeparator />
		<ZyroFieldRange
			v-model="padding"
			min="0"
			max="80"
			step="4"
			:label="$t('builder.blog.blockBlogList.layout.padding')"
		/>
		<ZyroSeparator />
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { cloneDeep } from '@/utils/object';

export default {
	data() {
		return {
			id: null,
			currentBlockBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentBlockId']),
		...mapGetters(['currentBlock']),
		...mapGetters('pages', ['publishedBlogPages']),
		maxPostsPerPage() {
			return Object.keys(this.publishedBlogPages).length;
		},
		postsPerPage: {
			// If some posts were deleted and this proprty has not been updated, do it.
			get() {
				const { postsPerPage } = this.currentBlock.settings;

				return postsPerPage > this.maxPostsPerPage ? this.maxPostsPerPage : postsPerPage;
			},
			set(event) {
				const payload = { data: { settings: { postsPerPage: event.target.value } } };

				this.setBlockData(payload);
			},
		},
		columnCount: {
			get() {
				return this.currentBlock.settings.styles['post-column-count'];
			},
			set(event) {
				this.setBlockData({ data: { settings: { styles: { 'post-column-count': event.target.value } } } });
			},
		},
		padding: {
			get() {
				return Number.parseInt(this.currentBlock.settings.styles['block-padding'], 10);
			},
			set(event) {
				this.setBlockData({ data: { settings: { styles: { 'block-padding': `${event.target.value}px` } } } });
			},
		},
		gridGapSize: {
			get() {
				return Number.parseInt(this.currentBlock.settings.styles['grid-gap-size'], 10);
			},
			set(event) {
				this.setBlockData({ data: { settings: { styles: { 'grid-gap-size': `${event.target.value}px` } } } });
			},
		},
	},
	mounted() {
		this.id = this.currentBlockId;
		this.currentBlockBeforeEdit = cloneDeep(this.currentBlock);
	},
	beforeDestroy() {
		this.pushBlockDataToHistory(
			{
				elementId: this.id,
				oldData: this.currentBlockBeforeEdit,
			},
		);
	},
	methods: mapMutations([
		'pushBlockDataToHistory',
		'setBlockData',
	]),
};
</script>
