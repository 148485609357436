<template>
	<div>
		<div class="categories-tab__button">
			<ZyroButton
				theme="outline"
				@click="handleManagePostsClick"
			>
				{{ $t('builder.blog.blockBlogList.managePosts') }}
			</ZyroButton>
		</div>
		<ZyroFieldToggle
			id="posts-from-selected"
			:label="$t('builder.blog.blockBlogList.categories.toggleText')"
			:checked="!showAllPosts"
			@input="showAllPosts = !showAllPosts"
		/>
		<ZyroLabel
			class="categories-tab__label z-body-small"
			:bold="false"
		>
			{{ $t('builder.blog.blockBlogList.categories.explanation') }}
		</ZyroLabel>
		<Transition name="fade">
			<div>
				<ZyroSeparator />
				<EditableItemsWithDropdown
					v-if="!showAllPosts"
					:is-editable="false"
					:editable-items="editableItems"
					:dropdown-items="items"
					:selected-items="selectedItems"
					:validate-value="validator"
					:placeholder="$t('builder.blog.blogPostSettings.writeCategories')"
					:button-text="$t('builder.blog.blogPostSettings.addCategory')"
					:title="$t('builder.blog.blogPostSettings.selectCategories')"
					@add="addItem"
					@update-items="updateFromEditableItems"
					@select="selectCategory"
					@deselect="deselectCategory"
				/>
			</div>
		</Transition>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
	mapActions,
} from 'vuex';

import EditableItemsWithDropdown from '@/components/reusable-components/editable-items-list/-partials/EditableItemsWithDropdown.vue';
import { BLOG_DRAWER } from '@/store/builder/constants/drawers';
import {
	mapActionsGui,
	OPEN_DRAWER,
} from '@/store/builder/gui';
import { cloneDeep } from '@/utils/object';

export default {
	components: { EditableItemsWithDropdown },
	data() {
		return {
			id: null,
			currentBlockBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentBlockId']),
		...mapGetters(['currentBlock']),
		...mapGetters('blog', [
			'categoriesNames',
			'blogListCategoriesNames',
			'categoryIdByName',
		]),
		showAllPosts: {
			get() {
				return this.currentBlock.settings.showAllPosts;
			},
			set(showAllPosts) {
				this.setBlockData({ data: { settings: { showAllPosts } } });
			},
		},
		withoutCategoriesText() {
			return this.$t('builder.blog.blockBlogList.withoutCategories');
		},
		items() {
			return [
				this.withoutCategoriesText,
				...this.categoriesNames,
			];
		},
		editableItems() {
			return this.selectedItems.map((name) => ({ name }));
		},
		blogListCategories() {
			return this.blogListCategoriesNames(this.currentBlockId);
		},
		selectedItems() {
			const selectedItems = this.blogListCategories;
			const hasCategoriesWithoutText = selectedItems.includes(this.withoutCategoriesText);

			if (this.currentBlock.settings.showWithoutCategories && !hasCategoriesWithoutText) {
				selectedItems.unshift(this.withoutCategoriesText);
			}

			return selectedItems;
		},
	},
	mounted() {
		this.id = this.currentBlockId;
		this.currentBlockBeforeEdit = cloneDeep(this.currentBlock);
	},
	beforeDestroy() {
		this.pushBlockDataToHistory({
			elementId: this.id,
			oldData: this.currentBlockBeforeEdit,
		});
	},
	methods: {
		...mapActions('blog', ['addBlogListCategory']),
		...mapActionsGui({ openDrawer: OPEN_DRAWER }),
		...mapMutations([
			'pushBlockDataToHistory',
			'setBlockData',
		]),
		validator(categoryName) {
			if (!categoryName) {
				return {
					isValid: false,
					error: this.$t('validate.emptyValue'),
				};
			}

			const isDuplicateValue = this.categoriesNames.includes(categoryName)
			|| categoryName === this.withoutCategoriesText;

			if (isDuplicateValue) {
				return {
					isValid: false,
					error: this.$t('builder.blog.blogPostSettings.error'),
				};
			}

			return {
				isValid: true,
				error: '',
			};
		},
		updateFromEditableItems(newItems) {
			const mappedItems = newItems.map(({ name }) => name);

			this.updateItems(mappedItems);
		},
		updateItems(categoryNames) {
			const newCategories = [...categoryNames];
			const indexOfWithoutCategoriesOption = newCategories.indexOf(this.withoutCategoriesText);
			const showWithoutCategories = indexOfWithoutCategoriesOption > -1;

			if (showWithoutCategories) {
				newCategories.splice(indexOfWithoutCategoriesOption, 1);
			}

			const categories = newCategories.map((categoryId) => this.categoryIdByName(categoryId));

			this.setBlockData({
				data: {
					settings: {
						showWithoutCategories,
						categories,
					},
				},
			});
		},
		handleManagePostsClick() {
			this.openDrawer(BLOG_DRAWER);
			this.$emit('close');
		},
		addItem(newItem) {
			this.addBlogListCategory({
				blockId: this.id,
				name: newItem,
			});
		},
		selectCategory(categoryName) {
			this.updateItems([
				categoryName,
				...this.blogListCategories,
			]);
		},
		deselectCategory(categoryName) {
			this.updateItems([...this.blogListCategories.filter((name) => name !== categoryName)]);
		},
	},
};
</script>

<style lang="scss" scoped>
.categories-tab {
	&__button {
		display: flex;
		justify-content: center;
		margin-top: 16px;
	}

	&__label {
		margin-bottom: 24px;
		color: $grey-800;
	}
}
</style>
