<template>
	<EditableItemsList
		:items="editableItems"
		:placeholder="placeholder"
		:validate-value="validateValue"
		:is-editable="isEditable"
		@edit="$emit('edit', $event)"
		@update-items="$emit('update-items', $event)"
	>
		<template slot="header">
			<EditableItemsAddButton
				:button-text="buttonText"
				:placeholder="placeholder"
				:validate-value="validateValue"
				@add="$emit('add', $event)"
			>
				<template #button="{ showInput }">
					<EditableItemsDropdown
						:items="dropdownItems"
						:selected-items="selectedItems"
						@select="$emit('select', $event)"
						@deselect="$emit('deselect', $event)"
					>
						<template #custom-buttons>
							<button
								class="dropdown-item dropdown-item--custom-button"
								data-qa="dropdown-add-button"
								:title="buttonText"
								@click="showInput"
							>
								<div class="dropdown-item__icon">
									<ZyroSvg name="plus" />
								</div>
								<p class="z-body-small">
									{{ buttonText }}
								</p>
							</button>
						</template>
					</EditableItemsDropdown>
				</template>
			</EditableItemsAddButton>
		</template>
		<template #item-button="{ item }">
			<ZyroButton
				icon="x"
				:title="$t('common.remove')"
				@click="$emit('deselect', item.name)"
			/>
		</template>
	</EditableItemsList>
</template>

<script>
import EditableItemsList from '@/components/reusable-components/editable-items-list/EditableItemsList.vue';

import EditableItemsAddButton from './EditableItemsAddButton.vue';
import EditableItemsDropdown from './EditableItemsDropdown.vue';

export default {
	components: {
		EditableItemsList,
		EditableItemsAddButton,
		EditableItemsDropdown,
	},
	props: {
		editableItems: {
			type: Array,
			default: () => [],
		},
		placeholder: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		buttonText: {
			type: String,
			default: '',
		},
		dropdownItems: {
			type: Array,
			default: () => [],
		},
		selectedItems: {
			type: Array,
			default: () => [],
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		validateValue: {
			type: Function,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/components/reusable-components/editable-items-list/-partials/DropdownItem';
</style>
