<template>
	<div>
		<slot
			name="trigger"
			:toggle="toggleDropdown"
		>
			<ZyroButton
				icon-left="plus-circle"
				class="add-button"
				@click="toggleDropdown"
			>
				{{ title }}
			</ZyroButton>
		</slot>
		<Transition name="slide-top-to-bottom">
			<div
				v-show="isDropdownShown"
				data-qa="edit-items-dropdown"
				class="dropdown dropdown-inner"
			>
				<div class="dropdown-inner border-bottom">
					<button
						v-for="item in items"
						:key="item"
						class="dropdown-item"
						data-qa="edit-items-dropdown-item"
						@click="handleSelect(item)"
					>
						<div>
							<ZyroSvg
								v-if="selectedItems.includes(item)"
								name="check-mark"
							/>
						</div>
						<p class="z-body-small">
							{{ item }}
						</p>
					</button>
				</div>
				<div v-if="$slots['custom-buttons']">
					<ZyroSeparator />
					<slot name="custom-buttons" />
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: () => ([]),
		},
		selectedItems: {
			type: Array,
			default: () => ([]),
		},
		title: {
			type: String,
			default() {
				return this.$t('builder.editItems.selectItems');
			},
		},
	},
	data() {
		return { isDropdownShown: false };
	},
	methods: {
		handleSelect(item) {
			const eventName = this.selectedItems.includes(item) ? 'deselect' : 'select';

			this.$emit(eventName, item);
		},
		toggleDropdown() {
			this.isDropdownShown = !this.isDropdownShown;
		},
	},
};
</script>

<style lang="scss" scoped>
@import './AddButton.scss';
@import './DropdownItem.scss';

.dropdown-inner {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 300px;
	overflow-y: auto;
}

.dropdown {
	position: absolute;
	z-index: 1;
	overflow-y: auto;
	border-radius: $border-radius-small;
	box-shadow: $box-shadow;
}

.border-bottom {
	border-bottom: 0.5px solid $grey-400;
}
</style>
