<template>
	<ZyroPopupCard
		type="editor"
		title="Blog list"
		:tabs="$options.tabs"
		:current-tab="currentTab"
		@change="currentTab = $event"
		@close="$emit('close')"
	>
		<Component
			:is="currentTab.component"
			@close="$emit('close')"
		/>
	</ZyroPopupCard>
</template>

<script>
import EditBlockTabBackground from '@/components/builder-controls/edit-block/EditBlockTabBackground.vue';
import i18n from '@/i18n/setup';

import EditBlockBlogListTabCategories from './EditBlockBlogListTabCategories.vue';
import EditBlockBlogListTabLayout from './EditBlockBlogListTabLayout.vue';
import EditBlockBlogListTabStyle from './EditBlockBlogListTabStyle.vue';

const tabs = [
	{
		id: 'layout',
		title: i18n.t('common.layout'),
		component: 'EditBlockBlogListTabLayout',
	},
	{
		id: 'style',
		title: i18n.t('common.style'),
		component: 'EditBlockBlogListTabStyle',
	},
	{
		id: 'categories',
		title: i18n.t('common.posts'),
		component: 'EditBlockBlogListTabCategories',
	},
	{
		id: 'background',
		title: i18n.t('common.background'),
		component: 'EditBlockTabBackground',
	},
];

export default {
	components: {
		EditBlockBlogListTabLayout,
		EditBlockBlogListTabStyle,
		EditBlockBlogListTabCategories,
		EditBlockTabBackground,
	},
	props: {
		startTabId: {
			type: String,
			default: '',
		},
	},
	data() {
		return { currentTab: null };
	},
	created() {
		this.currentTab = this.$options.tabs
			.find((tab) => tab.id === this.startTabId)
			|| this.$options.tabs[0];
	},
	tabs,
};
</script>
